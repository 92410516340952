import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './../components/home.vue';
import App from './../App.vue';
import store from './../store/index';

import Area from './../components/Sucursal.vue';
import ActualizarMiPassword from './../components/ActualizarMiPassword.vue';
import Login from './../components/Login.vue'
import Cliente from './../components/Cliente.vue';
import AsignarCliente from './../components/AsignarCliente.vue';
import Categorias from './../components/Departamento.vue';
import Puesto from './../components/Puesto.vue';
import Servicio from './../components/Servicio';
import ServicioProductos from './../components/ServicioProductos.vue';
import CaducidadProducto from './../components/CaducidadProducto.vue';

import informacionPaciente from './../components/InformacionCliente.vue';
import Producto from './../components/Producto.vue';
import Precio from './../components/Precio.vue';
import CEYE from './../components/CEYE.vue';

import Personal from './../components/Personal.vue';
import AplicacionPuestos from './../components/AplicacionPuestos.vue';
import MiPerfil from './../components/MiPerfil.vue';
import Configuracion from './../components/Configuracion.vue';
import validarTransferencias from './../components/FarmaciaHospitalaria/validarTransferencias.vue';
import validarTransferenciasCEYE from './../components/CEYE/validarTransferenciasCEYE.vue';
import verificarInventarioArea from './../components/Areas/verificarInventarioArea.vue';
import verificarInventarioAreaCEYE from './../components/Areas/verificarInventarioAreaCEYE.vue';
import generarSolicitudInventario from './../components/Areas/generarSolicitudInventario.vue';

import categoriaCeye from './../components/CEYE/DepartamentoCEYE.vue';


import ingresoPaciente from './../components/Recepcion/ingreso.vue';
import EnfermeriaPacientes from './../components/EnfermeriaPacientes.vue';
import AsignacionMedicamentos from './../components/AsignacionMedicamentos.vue';

import GenerarSolicitudInventarioCEYE from './../components/CEYE/generarSolicitudInventarioCEYE.vue';

import manejoServicio from './../components/TicketServicio/ManejoServicio.vue';

import historialMovimientoFarmaco from './../components/FarmaciaHospitalaria/historialMovimientoFarmaco.vue';

import historialMovimientoCEYE from './../components/CEYE/historialMovimientoCEYE.vue';

import consultaCuentaPaciente from './../components/Cuenta/consultaCuentaPaciente.vue';

import historialCuentaPaciente from './../components/Cuenta/historialCuentaPaciente.vue';

import consultaCuentaPacienteCliente from './../components/Cuenta/consultaCuentaPacienteCliente.vue';



Vue.use(VueRouter)




const routes = [{
        path: '/',
        name: '',
        component: Home,
        meta: {
            libre: true,
            title: 'Home'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            login: true,
            title: 'Home'
        }
    },
    {
        path: '/paciente',
        name: 'paciente',
        component: Cliente,
        meta: {
            login: true,
            title: 'Pacientes'
        }
    },
    {
        path: '/informacionPaciente/:id',
        name: 'informacionPaciente',
        component: informacionPaciente,
        meta: {
            login: true,
            title: 'Información del Paciente'
        }
    },
    {
        path: '/puesto',
        name: 'puesto',
        component: Puesto,
        meta: {
            login: true,
            title: 'Puesto'
        }
    },
    {
        path: '/precio',
        name: 'precio',
        component: Precio,
        meta: {
            login: true,
            title: 'Precio'
        }
    },
    {
        path: '/farmaciaHospitalaria',
        name: 'farmaciaHospitalaria',
        component: Producto,
        meta: {
            login: true,
            title: 'Farmacia Hospitalaria'
        }
    },
    {
        path: '/categoria',
        name: 'categoria',
        component: Categorias,
        meta: {
            login: true,
            title: 'Categoria'
        }
    },
    {
        path: '/area',
        name: 'area',
        component: Area,
        meta: {
            login: true,
            title: 'Areas'
        }
    },
    {
        path: '/servicios',
        name: 'servicios',
        component: Servicio,
        meta: {
            login: true,
            title: 'Servicios'
        }
    },
    {
        path: '/ceye',
        name: 'ceye',
        component: CEYE,
        meta: {
            login: true,
            title: 'CEYE'
        }
    },
    {
        path: '/asignacionConfiguracionEspacios',
        name: 'asignacionConfiguracionEspacios',
        component: Configuracion,
        meta: {
            login: true,
            title: 'Asignación de Configuracion de Espacios'
        }
    },
    {
        path: '/actualizarMiPassword',
        name: 'actualizarMiPassword',
        component: ActualizarMiPassword,
        meta: {
            login: true,
            title: 'Actualizar mi contraseña'
        }
    },
    {
        path: '/inventarioArea',
        name: 'inventarioArea',
        component: verificarInventarioArea,
        meta: {
            login: true,
            title: 'Inventario por Area Farmacos'
        }
    },
    {
        path: '/inventarioAreaCEYE',
        name: 'inventarioAreaCEYE',
        component: verificarInventarioAreaCEYE,
        meta: {
            login: true,
            title: 'Inventario por Area CEYE'
        }
    },
    {
        path: '/historialMovimientoFarmaco',
        name: 'historialMovimientoFarmaco',
        component: historialMovimientoFarmaco,
        meta: {
            login: true,
            title: 'Historial de Movimiento Farmaco'
        }
    },


    {
        path: '/historialMovimientoCEYE',
        name: 'historialMovimientoCEYE',
        component: historialMovimientoCEYE,
        meta: {
            login: true,
            title: 'Historial de Movimiento CEYE'
        }
    },


    
    {
        path: '/generarSolicitudInventario',
        name: 'generarSolicitudInventario',
        component: generarSolicitudInventario,
        meta: {
            login: true,
            title: 'Generar Solicitud de Inventario'
        }
    },
    {
        path: '/personal',
        name: 'personal',
        component: Personal,
        meta: {
            login: true,
            title: 'Personal'
        }
    },
    {
        path: '/appsPuestos',
        name: 'appsPuestos',
        component: AplicacionPuestos,
        meta: {
            login: true,
            title: 'Aplicaciones para Puestos'
        }
    },
    {
        path: '/categoriaCeye',
        name: 'categoriaCeye',
        component: categoriaCeye,
        meta: {
            login: true,
            title: 'Categorias en CEYE'
        }
    },
    {
        path: '/ingresoPaciente',
        name: 'ingresoPaciente',
        component: ingresoPaciente,
        meta: {
            login: true,
            title: 'Ingreso de Paciente'
        }
    },
    {
        path: '/manejoServicio/:folio',
        name: 'manejoServicio',
        component: manejoServicio,
        meta: {
            login: true,
            title: 'Manejo de Servicio'
        }
    },
    {
        path: '/miPerfil',
        name: 'miPerfil',
        component: MiPerfil,
        meta: {
            login: true,
            title: 'Editar perfil'
        }
    },
    {
        path: '/sertvicioProductos/:id',
        name: 'sertvicioProductos',
        component: ServicioProductos,
        meta: {
            login: true,
            title: 'Configrar productos de servicio'
        }
    },
    {
        path: '/validarTransferencias',
        name: 'validarTransferencias',
        component: validarTransferencias,
        meta: {
            login: true,
            title: 'Validar Transferencias'
        }

    },
    {
        path: '/validarTransferenciasCEYE',
        name: 'validarTransferenciasCEYE',
        component: validarTransferenciasCEYE,
        meta: {
            login: true,
            title: 'Validar Transferencias CEYE'
        }

    },
    {
        path: '/enfermeriaPacientes',
        name: 'enfermeriaPacientes',
        component: EnfermeriaPacientes,
        meta: {
            login: true,
            title: 'Enfermería'
        }

    },
    {
        path: '/enfermeriaAsignacionMedicamentos',
        name: 'enfermeriaAsignacionMedicamentos',
        component: AsignacionMedicamentos,
        meta: {
            login: true,
            title: 'Asignación de medicamentos'
        }

    },
    {
        path: '/verificarCaducidad',
        name: 'verificarCaducidad',
        component: CaducidadProducto,
        meta: {
            login: true,
            title: 'Verificar caducidad'
        }
    },
    {
        path: '/asingarAreaPaciente',
        name: 'asingarAreaPaciente',
        component: AsignarCliente,
        meta: {
            login: true,
            title: 'Asignar Area a Paciente'
        }
    },
    {
        path: '/generarSolicitudInventarioCEYE',
        name: 'generarSolicitudInventarioCEYE',
        component: GenerarSolicitudInventarioCEYE,
        meta: {
            login: true,
            title: 'Inventario Concentrado CEYE'
        }
    },
    {
        path: '/consultaCuentaPaciente',
        name: 'consultaCuentaPaciente',
        component: consultaCuentaPaciente,
        meta: {
            login: true,
            title: 'Consulta de Cuenta de Paciente'
        }
    },
    {
        path: '/historialCuentaPaciente',
        name: 'historialCuentaPaciente',
        component: historialCuentaPaciente,
        meta: {
            login: true,
            title: 'Historial de cuenta del paciente'
        }
        
    },
    {
        path: '/consultaCuentaPacienteCliente/:id',
        name: 'consultaCuentaPacienteCliente',
        component: consultaCuentaPacienteCliente,
        meta: {
            libre: true,
            login: false,
            title: 'Consulta de Cuenta de Paciente'
        }
    },


    
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    //document.title = to.meta.title;
    
    ///if (to.matched[0].meta.libre == true){
    ///    
    ///    localStorage.usuario = "invitado";
    ///    localStorage.token = "invitado";
    ///    next();
    ///} else
    if (!localStorage.usuario) {
        
        if (to.name != "") {
            next({ name: "" });
        }
    }

    next();

    //if(!localStorage.getItem("usuario") && from.name!="login"){
    //  next({name:'login'});
    //} else if (localStorage.getItem("usuario") && to.name=="login"){
    //  next({name:'home'});
    //} else if (to.matched.some(record => record.meta.libre)){
    //  next();
    //}



    /// else if (localStorage.usuario /*&& localStorage.usuario.rol == 'Administrador'*/){
    ///  if (to.matched.some(record=> record.meta.administrador)){
    ///    next();
    ///  }
    ///} else if (localStorage.usuario){
    ///  if (to.matched.some(record=>record.meta.login)){
    ///    next();
    ///  }
    ///}   else {
    ///  next({name:'home'});
    ///}

});

export default router